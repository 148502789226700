import React from 'react';
import { Heading, Stack, Link } from '@chakra-ui/react';
import GridLink from './GridLink';
import logo from '../../assets/logo.svg';
import styled from 'styled-components';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--section-bm);
`;

const Logo = styled.img`
  width: 1.5rem;
  margin-top: -5px;
`;

const CopyFlex = styled.div`
  display: flex;
  column-gap: 2rem;
  margin-top: 10rem;

  @media only screen and (max-width: 670px) {
    flex-direction: column-reverse;
    row-gap: 1rem;
    align-items: flex-start;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Stack spacing="0.5rem" direction="row" align="center" mt="10rem">
        <Logo src={logo} alt="logo" />
        <Heading
          as="h1"
          size="lg"
          textColor="var(--components-primary-orange)"
          fontSize="1.5rem"
          fontFamily="Spline Sans Mono"
          fontWeight="300"
        >
          Karak
        </Heading>
      </Stack>
      <GridLink />
      <CopyFlex>
        <p style={{color: '#303030', fontSize: '0.75rem'}}>&copy; {new Date().getFullYear()} Karak. All rights reserved.</p>
        <Link style={{color: '#303030', fontSize: '0.75rem'}} _focus={{ outline: 'none !important'}} href="/terms-of-service">Terms of Service</Link>
        <Link style={{color: '#303030', fontSize: '0.75rem'}} _focus={{ outline: 'none !important'}} href="/privacy-policy">Privacy Policy</Link>
      </CopyFlex>
    </FooterContainer>
  );
}

export default Footer;
