import React from 'react';
import { Button, Heading, Stack, Link } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import whiteLogo from '../../assets/logo-white.svg';
import orangeLogo from '../../assets/logo.svg';
import styled from 'styled-components';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 3rem;
`;

const Logo = styled.img`
  width: 1.5rem;
  margin-top: -5px;
`;

const EnterAppDiv = styled.div`
  @media only screen and (max-width: 780px) {
    display: none;
  }
`;

const Header = (props) => {
  return (
    <Container>
      <RouteLink to="/" style={{zIndex: 999}}>
        <Stack spacing="0.5rem" direction="row" _hover={{ cursor: 'pointer' }}>
          <Logo src={props.light ? orangeLogo : whiteLogo} alt="logo" />
          <Heading
            as="h1"
            size="lg"
            textColor={props.light ? "var(--components-primary-orange)" : "white"}
            fontSize="1.5rem"
            fontFamily="Spline Sans Mono"
            fontWeight="300"
          >
            Karak
          </Heading>
        </Stack>
      </RouteLink>
      <DesktopMenu light={props.light} />
      <EnterAppDiv>
        <Button
          as={Link}
          href="https://app.karak.network"
          isExternal
          _hover={{textDecoration: 'none', filter: 'brightness(95%)'}}
          _active={{color: 'transparent'}}
          _focus={{outline: 'none', boxShadow: 'none'}}
          px="var(--button-px)"
          py="var(--button-py)"
          textColor={props.light ? "#000" : "#fff"}
          target="_new"
          background="linear-gradient(0deg, rgba(255, 255, 255, 0.28) 0%, rgba(255, 255, 255, 0.28) 100%), rgba(255, 255, 255, 0.10)"
          boxShadow={props.light ? "none" : "0px 4px 4px 0px rgba(0, 0, 0, 0.10)"}
          fontSize="0.8rem"
          border={props.light ? "0.75px solid rgba(48, 48, 48, 0.20)" : "0.2px solid #FFF"}
          borderRadius="5px"
          width="7rem"
          height="2.25rem"
          fontFamily="Nunito Sans"
          fontWeight="700"
          marginLeft="auto"
        >
          Restake now
        </Button>
      </EnterAppDiv>
      <MobileMenu light={props.light} />
    </Container>
  );
}

export default Header;
