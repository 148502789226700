import React from 'react';
import { Link, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';
import styled from 'styled-components';

const DisplayMenu = styled.div`
  display: flex;
  position: relative;
  column-gap: 2rem;
  z-index: 999 !important;
  margin: auto;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(15px);
  padding: 0.5rem 2rem;
  border-radius: 100px;

  @media only screen and (max-width: 780px) {
    display: none;
  }
`;

const DesktopMenu = (props) => {
  const boxShadow = props.light ? "none" : "0px 4px 4px 0px rgba(0, 0, 0, 0.10)";
  const border = props.light ? "0.75px solid rgba(48, 48, 48, 0.20)" : "0.2px solid #FFF";

  return (
    <DisplayMenu style={{ border: border, boxShadow: boxShadow }}>
      <Menu>
        <MenuButton
          color="#000"
          fontSize="0.8rem"
          fontWeight="700"
          fontFamily="Nunito Sans"
          _focus={{ outline: 'none !important'}}
          _hover={{ textDecoration: 'underline !important'}}
        >
          Explore
          <ChevronDownIcon ml={1} boxSize={4} />
        </MenuButton>
        <MenuList
          display="flex"
          flexDirection="column"
          minW="50px"
          bg="linear-gradient(90deg, #DF5E00 0%, #FF9C54 100%)"
          border="transparent"
        >
          <Link 
            color="white"
            fontSize="0.8rem"
            fontWeight="700"
            fontFamily="Nunito Sans"
            _focus={{ outline: 'none !important'}}
            paddingLeft="20px"
            paddingTop="5px"
            paddingBottom="5px"
            paddingRight="20px"
            href="https://app.karak.network"
            isExternal
          >
            Restaking
          </Link>
          <Link
            as={RouteLink}
            color="white"
            fontSize="0.8rem"
            fontWeight="700"
            fontFamily="Nunito Sans"
            paddingLeft="20px"
            paddingTop="5px"
            paddingBottom="8px"
            paddingRight="20px"
            _focus={{ outline: 'none !important'}}
            to="/ecosystem"
          >
            Ecosystem
          </Link>
          <Link
            as={RouteLink}
            color="white"
            fontSize="0.8rem"
            fontWeight="700"
            fontFamily="Nunito Sans"
            paddingLeft="20px"
            paddingTop="5px"
            paddingBottom="8px"
            paddingRight="20px"
            _focus={{ outline: 'none !important'}}
            to="/bridge"
          >
            K2 Bridge
          </Link>
        </MenuList>
      </Menu>
      <Menu>
        <MenuButton
          color="#000"
          fontSize="0.8rem"
          fontWeight="700"
          fontFamily="Nunito Sans"
          _focus={{ outline: 'none !important'}}
          _hover={{ textDecoration: 'underline !important'}}
        >
          Developers
          <ChevronDownIcon ml={1} boxSize={4} />
        </MenuButton>
        <MenuList
          display="flex"
          flexDirection="column"
          minW="50px"
          bg="linear-gradient(90deg, #DF5E00 0%, #FF9C54 100%)"
          border="transparent"
        >
          <Link 
            color="white"
            fontSize="0.8rem"
            fontWeight="700"
            fontFamily="Nunito Sans"
            _focus={{ outline: 'none !important'}}
            paddingLeft="20px"
            paddingTop="5px"
            paddingBottom="5px"
            paddingRight="20px"
            href="https://airtable.com/appomORhKwfeQrCIk/shrla3jWOjs7fId8j"
            isExternal
          >
            Build (Get in touch)
          </Link>
          <Link 
            color="white"
            fontSize="0.8rem"
            fontWeight="700"
            fontFamily="Nunito Sans"
            _focus={{ outline: 'none !important'}}
            paddingLeft="20px"
            paddingTop="5px"
            paddingBottom="8px"
            paddingRight="20px"
            href="https://docs.karak.network"
            isExternal
          >
            Docs
          </Link>
          {/* <Link 
            color="white"
            fontSize="0.8rem"
            fontWeight="700"
            fontFamily="Nunito Sans"
            _focus={{ outline: 'none !important'}}
            paddingLeft="14px"
            paddingTop="5px"
            paddingBottom="8px"
            paddingRight="14px"
            href="https://docs.karak.network"
            isExternal
          >
            GitHub
          </Link> */}
        </MenuList>
      </Menu>
      <Menu>
        <MenuButton
          color="#000"
          fontSize="0.8rem"
          fontWeight="700"
          fontFamily="Nunito Sans"
          _focus={{ outline: 'none !important'}}
          _hover={{ textDecoration: 'underline !important'}}
        >
          Community
          <ChevronDownIcon ml={1} boxSize={4} />
        </MenuButton>
        <MenuList
          display="flex"
          flexDirection="column"
          minW="50px"
          bg="linear-gradient(90deg, #DF5E00 0%, #FF9C54 100%)"
          border="transparent"
        >
          <Link 
            color="white"
            fontSize="0.8rem"
            fontWeight="700"
            fontFamily="Nunito Sans"
            _focus={{ outline: 'none !important'}}
            paddingLeft="20px"
            paddingTop="5px"
            paddingBottom="8px"
            paddingRight="20px"
            href="https://twitter.com/Karak_Network"
            isExternal
          >
            X (Twitter)
          </Link>
          <Link 
            color="white"
            fontSize="0.8rem"
            fontWeight="700"
            fontFamily="Nunito Sans"
            _focus={{ outline: 'none !important'}}
            paddingLeft="20px"
            paddingTop="5px"
            paddingBottom="5px"
            paddingRight="20px"
            href="https://discord.gg/7nJEVrw4Fh"
            isExternal
          >
            Discord
          </Link>
          <Link 
            color="white"
            fontSize="0.8rem"
            fontWeight="700"
            fontFamily="Nunito Sans"
            _focus={{ outline: 'none !important'}}
            paddingLeft="20px"
            paddingTop="5px"
            paddingBottom="5px"
            paddingRight="20px"
            href="https://t.me/Karak_Network"
            isExternal
          >
            Telegram
          </Link>
          <Link 
            color="white"
            fontSize="0.8rem"
            fontWeight="700"
            fontFamily="Nunito Sans"
            _focus={{ outline: 'none !important'}}
            paddingLeft="20px"
            paddingTop="5px"
            paddingBottom="5px"
            paddingRight="20px"
            href="https://blog.karak.network"
            isExternal
          >
            Blog
          </Link>
        </MenuList>
      </Menu>
    </DisplayMenu>
  );
}

export default DesktopMenu;