import React from 'react';
import { Box, Center } from '@chakra-ui/react';
import Firm from './Firm';
import Angel from './Angel';
import styled from 'styled-components';
import pantera from '../../assets/investor-logos/pantera.png';
import framework from '../../assets/investor-logos/framework.png';
import bainCapital from '../../assets/investor-logos/bcv.png';
import dcg from '../../assets/investor-logos/dcg.png';
import coinbase from '../../assets/investor-logos/coinbase.png';
import nimaCapital from '../../assets/investor-logos/nima-capital.png';
import mubadalaCapital from '../../assets/investor-logos/mubadala-capital.png';
import lightspeed from '../../assets/investor-logos/lightspeed.png';
import proofGroup from '../../assets/investor-logos/proof-group.png';

const Container = styled(Center)`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: var(--section-bm);
`;

const Heading = styled.h1`
  text-align: center;
  color: #303030;
  font-size: 2.5rem;
  line-height: var(--header-line-height);
  font-family: Gilroy;
  font-weight: 800;
  letter-spacing: -0.96px;
  line-height: 1.15;
  margin-bottom: 1rem;
`;

const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 1rem;
  column-gap: 0rem;
  margin: auto auto auto 2.5rem;
  max-width: 90%;
  width: 100%;
  z-index: 999;

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: auto auto;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    grid-template-columns: auto;
    max-width: 400px;
    margin: auto;
  }
`;

const firms = [
  {
    src: coinbase,
    alt: "Coinbase Ventures"
  },
  {
    src: mubadalaCapital,
    alt: "Mubadala Capital"
  },
  {
    src: lightspeed,
    alt: "Lightspeed Venture Partners"
  },
  {
    src: pantera,
    alt: "Pantera Capital"
  },
  {
    src: framework,
    alt: "Framework Ventures"
  },
  {
    src: bainCapital,
    alt: "Bain Capital Ventures"
  },
  {
    src: dcg,
    alt: "Digital Currency Group"
  },
  {
    src: proofGroup,
    alt: "Proof Group"
  },
  {
    src: nimaCapital,
    alt: "Nima Capital"
  }
];

const angels = [
  {
    name: "Naval Ravikant",
    occupation: "Founder, AngelList"
  },
  {
    name: "Anthony Pompliano",
    occupation: "Founder, Pomp Investments"
  },
  {
    name: "Santiago Roel Santos",
    occupation: "Partner, ParaFi Capital"
  },
  {
    name: "Noah Jessop",
    occupation: "Partner, Proof Group"
  },
  {
    name: "Napoleon Ta",
    occupation: "Partner, Founders Fund"
  },
  {
    name: "Ashleigh Schap",
    occupation: "CSO, Risc Zero"
  },
  {
    name: "Nikil Viswanathan",
    occupation: "Co-founder & CEO, Alchemy"
  },
  {
    name: "Joe Lau",
    occupation: "Co-founder & CTO, Alchemy"
  },
  {
    name: "+ Others From",
    occupation: "Google, Coinbase, and more"
  },
];

const Investors = () => {
  return (
    <Container>
      <Heading>Investors</Heading>
      <GridBox>
        {firms.map((item, index) =>
          <Firm logo={item.src} altText={item.alt} key={index} />
        )}
        {angels.map((item, index) =>
          <Angel name={item.name} occupation={item.occupation} key={index} />
        )}
      </GridBox>
    </Container>
  );
}

export default Investors;
